<template>
  <div>
    <v-data-table
      :headers="head"
      :items="data"
      :search="search"
      mobile-breakpoint="1150"
      :loading="tableLoading"
      loading-text="Loading... Please wait"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
      @pagination="(item) => $emit('pageChange', item)"
      @click:row="(item) => $emit('edititem', item)"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <img
          :src="checkAvatar(item)"
          width="50px"
          style="width: 65px; height: 70px; margin: 10px; border-radius: 50px"
        />
      </template>

      <template v-slot:[`item.complaint_goods_name`]="{ item }">
        <div v-for="(good, index) in item.complaint_goods_name" :key="good">
          {{ good }}
          <v-divider
            v-if="index < item.complaint_goods_name.length - 1"
            class="my-2"
          />
        </div>
      </template>

      <template v-slot:[`item.complaint_goods_number`]="{ item }">
        <div v-for="(good, index) in item.complaint_goods_number" :key="good">
          {{ good }}
          <v-divider
            v-if="index < item.complaint_goods_number.length - 1"
            class="my-2"
          />
        </div>
      </template>

      <template v-slot:[`item.image`]="{ item }">
        <img
          :src="checkImage(item)"
          width="50px"
          style="width: 65px; height: 70px; margin: 10px; border-radius: 50px"
        />
      </template>

      <template v-slot:[`item.category`]="{ item }">
        <label v-if="item.category == '1'">Product</label>
      </template>

      <template v-slot:[`item.itemNo`]="{ item }">
        <label>{{ item.itemNo }}</label>
      </template>

      <template v-slot:[`item.is_reply`]="{ item }">
        <label>{{ item.is_reply ? "Yes" : "No" }}</label>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <label v-if="item.status == '0'">No</label>
        <label v-if="item.status == '1'">Yes</label>
      </template>

      <template v-slot:[`item.tax`]="{ item }">
        <label>{{ item.tax + "%" }}</label>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="permissionCheck('modify')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green"
              @click.stop="$emit('edititem', item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('del')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('deleteitem', item)"
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
        </v-tooltip>
      </template>

      <template
        v-slot:[`item.purchaseActions`]="{ item }"
        v-if="
          permissionCheck('modify', 'purchaseReceive') ||
          permissionCheck('modify', 'purchase_order') ||
          permissionCheck('modify', 'purchaseAudit') ||
          permissionCheck('del', 'purchase_order')
        "
      >
        <template v-if="item.status == 1">
          <v-tooltip top v-if="permissionCheck('modify', 'purchase_order')">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                color="green"
                @click.stop="$emit('edititem', item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
          </v-tooltip>

          <v-tooltip top v-if="permissionCheck('modify', 'purchaseAudit')">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="$emit('audit', item)"
                color="blue"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
              >
                mdi-checkbox-marked-circle
              </v-icon>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.audit") }}</span>
          </v-tooltip>

          <v-tooltip top v-if="permissionCheck('del', 'purchase_order')">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="$emit('abolish', item)"
                color="red"
                v-bind="attrs"
                v-on="on"
              >
                mdi-cancel
              </v-icon>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.abolish") }}</span>
          </v-tooltip>
        </template>

        <template v-if="item.status == 2">
          <v-tooltip
            disabled
            v-if="permissionCheck('modify', 'purchaseReceive')"
          >
            <template v-slot:activator="{ on, attrs }">
              <a
                style="color: blue"
                @click.stop="$emit('toBeReceived', item)"
                v-bind="attrs"
                v-on="on"
              >
                {{ $vuetify.lang.t("$vuetify.tobeReceived") }}
              </a>
            </template>
          </v-tooltip>
        </template>

        <template v-if="item.status == 3">
          <v-tooltip disabled>
            <template v-slot:activator="{ on, attrs }">
              <a
                style="color: blue"
                @click.stop="$emit('haveArrived', item)"
                v-bind="attrs"
                v-on="on"
              >
                {{ $vuetify.lang.t("$vuetify.unfloatcargo") }}
              </a>
            </template>
          </v-tooltip>
        </template>

        <template v-if="item.status == -1">
          <v-tooltip top v-if="permissionCheck('del', 'purchase_order')">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="$emit('deleteitem', item)"
                color="red"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
          </v-tooltip>
        </template>
      </template>

      <template v-slot:[`item.stockOutActions`]="{ item }">
        <template v-if="item.status == 1">
          <v-tooltip top v-if="permissionCheck('modify', 'picking')">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                color="green"
                @click.stop="$emit('edititem', item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
          </v-tooltip>

          <v-tooltip top v-if="permissionCheck('modify', 'pickingAudit')">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="$emit('audit', item)"
                color="blue"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
              >
                mdi-checkbox-marked-circle
              </v-icon>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.audit") }}</span>
          </v-tooltip>

          <v-tooltip top v-if="permissionCheck('del')">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="$emit('abolish', item)"
                color="red"
                v-bind="attrs"
                v-on="on"
              >
                mdi-cancel
              </v-icon>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.abolish") }}</span>
          </v-tooltip>
        </template>

        <template v-if="item.status == 2">
          <v-tooltip disabled v-if="permissionCheck('modify', 'pickDelivery')">
            <template v-slot:activator="{ on, attrs }">
              <a
                style="color: blue"
                @click.stop="$emit('deliveryCompleted', item)"
                v-bind="attrs"
                v-on="on"
              >
                {{ $vuetify.lang.t("$vuetify.deliveryCompleted") }}
              </a>
            </template>
          </v-tooltip>
        </template>

        <template v-if="item.status == 3 && permissionCheck('modify')">
          <v-tooltip disabled>
            <template v-slot:activator="{ on, attrs }">
              <a
                style="color: blue"
                @click.stop="$emit('saleReturn', item)"
                v-bind="attrs"
                v-on="on"
              >
                {{ $vuetify.lang.t("$vuetify.saleReturn") }}
              </a>
            </template>
          </v-tooltip>
        </template>
      </template>

      <template v-slot:[`item.stockOutProductCategory`]>
        <span>
          <v-chip dark small class="my-0" color="blue">
            {{ $vuetify.lang.t("$vuetify.commodity") }}
          </v-chip>
        </span>
      </template>

      <template v-slot:[`item.stockList`]="{ item }">
        <v-tooltip top disabled>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.stop="goodsJump(item.id)"
              color="white"
              class="pa-0 ma-0 border-0"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <span style="color: blue">Stock List</span>
            </v-btn>
          </template>
        </v-tooltip>
      </template>

      <template v-slot:[`item.stockInOut`]="{ item }">
        <v-tooltip top disabled>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.stop="LogJump(item.id)"
              color="white"
              class="pa-0 ma-0 border-0"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <span style="color: blue">{{
                $vuetify.lang.t("$vuetify.stockInOutRec")
              }}</span>
            </v-btn>
          </template>
        </v-tooltip>
      </template>

      <template v-slot:[`item.returnStatus`]="{ item }">
        <span
          v-for="returnOrder in item.return_order"
          :key="returnOrder.id"
          class="px-1"
        >
          <span v-if="returnOrder.status == 1">
            <v-chip
              dark
              small
              class="my-1"
              color="green"
              @click.stop="
                $emit('saleReturn', item, returnOrder.id, returnOrder.status)
              "
            >
              {{ $vuetify.lang.t("$vuetify.application") }}
            </v-chip>
          </span>

          <span v-if="returnOrder.status == 2">
            <v-chip
              dark
              small
              class="my-1"
              color="orange"
              @click.stop="
                $emit('saleReturn', item, returnOrder.id, returnOrder.status)
              "
            >
              {{ $vuetify.lang.t("$vuetify.confirm") }}
            </v-chip>
          </span>

          <span v-if="returnOrder.status == 3">
            <v-chip
              dark
              small
              class="my-1"
              color="red"
              @click.stop="
                $emit('saleReturn', item, returnOrder.id, returnOrder.status)
              "
            >
              {{ $vuetify.lang.t("$vuetify.offStocks") }}
            </v-chip>
          </span>

          <span v-if="returnOrder.status == -1">
            <v-chip
              dark
              small
              class="my-1"
              color="#9E9E9E"
              @click.stop="
                $emit('saleReturn', item, returnOrder.id, returnOrder.status)
              "
            >
              {{ $vuetify.lang.t("$vuetify.abolish") }}
            </v-chip>
          </span>
        </span>
      </template>

      <template v-slot:[`item.arrivalGood`]="{ item }">
        <v-tooltip
          top
          v-if="
            permissionCheck('modify') &&
            permissionCheck('del') &&
            item.status <= 0
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green"
              @click.stop="$emit('edititem', item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('del') && item.status <= 0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('deleteitem', item)"
              color="red"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="permissionCheck('list', 'customerDelive') && item.status === 1"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('viewitem', item)"
              color="grey"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.view") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="
            permissionCheck('list', 'customerDelive') &&
            (item.status === 0 || item.status === 1)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('arrived', item)"
              color="light-green"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              mdi-truck-fast
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.arrived") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="permissionCheck('list', 'customerDelive') && item.status === 1"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('freight', item)"
              color="black"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              mdi-calculator
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.freight") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.stockOutStatus`]="{ item }">
        <v-chip dark color="purple" v-if="item.status == 1"
          >Pending Review</v-chip
        >
        <v-chip color="primary" v-if="item.status == 2">Completed</v-chip>
        <v-chip dark color="orange" v-if="item.status == 3"
          >Out of Stock</v-chip
        >
        <v-chip dark color="red" v-if="item.status == -1">Cancel</v-chip>
      </template>

      <template v-slot:[`item.CustomerListAction`]="{ item }">
        <v-tooltip top v-if="permissionCheck('modify')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green"
              @click.stop="$emit('edititem', item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('del')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('deleteitem', item)"
              color="red"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('list', 'order_receipt')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="light-green"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click.stop="customerGather(item.id)"
            >
              mdi-file-document
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.Gathering") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('list', 'customer_account')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="dark-grey"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click.stop="customerUser(item.id)"
            >
              mdi-account-multiple
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.customerAccount") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.SupplierActions`]="{ item }">
        <v-tooltip top v-if="permissionCheck('modify')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green"
              @click.stop="$emit('edititem', item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('del')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('deleteitem', item)"
              color="red"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('list', 'supplier_account')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="light-green"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click.stop="supplierUser(item.id)"
            >
              mdi-account-box
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.Supplier") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('list', 'supplier_pay')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="dark-grey"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click.stop="supplierPay(item)"
            >
              mdi-file-document
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.paymentOrder") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.shippingStatus`]="{ item }">
        <v-chip color="primary">{{ getShippingStatus(item) }}</v-chip>
      </template>

      <template v-slot:[`item.change`]="{ item }">
        <v-chip
          :color="item.change < 0 ? 'red' : 'blue'"
          class="font-weight-bold"
          dark
        >
          {{ formatNumber(item.change) }}
        </v-chip>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        {{ checkStrLength(item.note) }}
        <span class="blue--text" v-if="item.note.length > 30">.....more</span>
      </template>

      <template v-slot:[`item.address`]="{ item }">
        {{ checkStrLength(item.address) }}
        <span class="blue--text" v-if="item.address.length > 30"
          >.....more
        </span>
      </template>

      <template v-slot:[`item.order_num`]="{ item }">
        {{ formatNumber(item.order_num) }}
      </template>

      <template v-slot:[`item.order_price`]="{ item }">
        {{ formatNumber(item.order_price) }}
      </template>

      <template v-slot:[`item.number`]="{ item }">
        {{ formatNumber(item.number) }}
      </template>

      <template v-slot:[`item.goods_num`]="{ item }">
        {{ formatNumber(item.goods_num) }}
      </template>

      <template v-slot:[`item.totalReceive`]="{ item }">
        {{ formatNumber(item.totalReceive) }}
      </template>

      <template v-slot:[`item.totalReturn`]="{ item }">
        {{ formatNumber(item.totalReturn) }}
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ formatNumber(item.price) }}
      </template>

      <template v-slot:[`item.inventory`]="{ item }">
        {{ formatNumber(item.inventory) }}
      </template>

      <template v-slot:[`item.total_order`]="{ item }">
        {{ formatNumber(item.total_order) }}
      </template>

      <template v-slot:[`item.total_money`]="{ item }">
        {{ formatNumber(item.total_money) }}
      </template>

      <template v-slot:[`item.totalQty`]="{ item }">
        {{ formatNumber(item.totalQty) }}
      </template>

      <template v-slot:[`item.totalProductType`]="{ item }">
        {{ formatNumber(item.totalProductType) }}
      </template>
    </v-data-table>

    <div class="text-center mt-4" v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-spacer />

        <v-col cols="4" class="mt-2">
          <v-pagination v-model="page" :length="pageCount" />
        </v-col>

        <v-spacer />

        <v-col cols="1" class="d-flex justify-center align-center">
          <v-text-field
            readonly
            label="Total Pages"
            :value="pageCount"
            class="noUnderline"
            disabled
          />
        </v-col>

        <v-col cols="2">
          <v-text-field
            min="1"
            v-model.number="page"
            label="Jump to page"
            type="number"
            @focus="$event.target.select()"
            @change="pageChange"
          />
        </v-col>
      </v-row>
    </div>

    <div class="text-center mt-4" v-else>
      <v-row>
        <v-spacer />

        <v-col cols="8" class="mt-2">
          <v-pagination v-model="page" :length="pageCount" />
        </v-col>

        <v-spacer />

        <v-col cols="6" class="d-flex justify-center align-center">
          <v-text-field
            readonly
            label="Total Pages"
            :value="pageCount"
            class="noUnderline"
            disabled
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
            min="1"
            v-model.number="page"
            label="Jump to page"
            type="number"
            @focus="$event.target.select()"
            @change="pageChange"
          />
        </v-col>
      </v-row>
    </div>

    <!-- <div class="text-center mt-4">
      <v-row>
        <v-spacer />

        <v-col cols="12" lg="6" class="mt-2">
          <v-pagination v-model="page" :length="pageCount" />
        </v-col>

        <v-spacer />

        <v-col cols="12" lg="2">
          <v-text-field
            min="1"
            v-model.number="page"
            label="Jump to page"
            type="number"
            @change="pageChange"
          />
        </v-col>
      </v-row>
    </div> -->
  </div>
</template>

<script>
import serverConfig from "@/utils/serverConfig";

export default {
  name: "tableImg",
  props: {
    head: Array,
    data: Array,
    search: String,
    dialog: Boolean,
    tableLoading: Boolean,
  },
  data() {
    return {
      tempBlank: serverConfig.blank_img,
      blankIMG: serverConfig.blank_profile_img,
      imgUrl: serverConfig.img_url,
      avatar: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  methods: {
    getShippingStatus(item) {
      const status = {
        0: "Floating Cargo",
        1: "Have Arrvied",
      };

      return status[item.shippingStatus];
    },
    pageChange() {
      if (this.page.length == 0 || this.page == "") {
        this.$toast.warning("Can not input Page 0 or empty");
        this.page = 1;
      }
    },
    editItem(item) {
      console.log(this.$store.getters.per);
    },
    customerGather(id) {
      this.$router.push("gathering/" + id);
    },
    customerUser(id) {
      this.$router.push({
        path: "./user",
        query: {
          id: id,
        },
      });
    },
    supplierUser(id) {
      this.$router.push({
        path: "./supplierAccount",
        query: {
          id: id,
        },
      });
    },
    supplierPay(item) {
      this.$router.push({
        path: "./supplierPay",
        query: {
          id: item.id,
        },
      });
    },
    goodsJump(id) {
      this.$router.push("/purchase/goods/" + id);
    },
    LogJump(id) {
      this.$router.push("/purchase/log/" + id);
    },
    checkAvatar(item) {
      return typeof item.avatar === "string" || item.avatar == undefined
        ? item.avatar == "" || item.avatar == undefined
          ? this.tempBlank
          : this.imgUrl + item.avatar
        : item.avatar.length != 0
        ? this.imgUrl + item.avatar[0]
        : this.tempBlank;
    },
    checkImage(item) {
      return typeof item.image === "string"
        ? item.image == ""
          ? this.blankProduct
          : this.imgUrl + item.image
        : item.image.length != 0
        ? this.imgUrl + item.image[0]
        : this.blankProduct;
    },
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      } else {
        return item;
      }
    },
  },
};
</script>


<style lang="sass">
.pagination-navigation-width
  width: 40px

.noUnderline > .v-input__control > .v-input__slot:before
  border-style: none
</style>