var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.head,"items":_vm.data,"search":_vm.search,"mobile-breakpoint":"1150","loading":_vm.tableLoading,"loading-text":"Loading... Please wait","page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"pagination":function (item) { return _vm.$emit('pageChange', item); },"click:row":function (item) { return _vm.$emit('edititem', item); }},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"65px","height":"70px","margin":"10px","border-radius":"50px"},attrs:{"src":_vm.checkAvatar(item),"width":"50px"}})]}},{key:"item.complaint_goods_name",fn:function(ref){
var item = ref.item;
return _vm._l((item.complaint_goods_name),function(good,index){return _c('div',{key:good},[_vm._v(" "+_vm._s(good)+" "),(index < item.complaint_goods_name.length - 1)?_c('v-divider',{staticClass:"my-2"}):_vm._e()],1)})}},{key:"item.complaint_goods_number",fn:function(ref){
var item = ref.item;
return _vm._l((item.complaint_goods_number),function(good,index){return _c('div',{key:good},[_vm._v(" "+_vm._s(good)+" "),(index < item.complaint_goods_number.length - 1)?_c('v-divider',{staticClass:"my-2"}):_vm._e()],1)})}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"65px","height":"70px","margin":"10px","border-radius":"50px"},attrs:{"src":_vm.checkImage(item),"width":"50px"}})]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category == '1')?_c('label',[_vm._v("Product")]):_vm._e()]}},{key:"item.itemNo",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.itemNo))])]}},{key:"item.is_reply",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.is_reply ? "Yes" : "No"))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == '0')?_c('label',[_vm._v("No")]):_vm._e(),(item.status == '1')?_c('label',[_vm._v("Yes")]):_vm._e()]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.tax + "%"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permissionCheck('modify'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]):_vm._e(),(_vm.permissionCheck('del'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])]):_vm._e()]}},(
        _vm.permissionCheck('modify', 'purchaseReceive') ||
        _vm.permissionCheck('modify', 'purchase_order') ||
        _vm.permissionCheck('modify', 'purchaseAudit') ||
        _vm.permissionCheck('del', 'purchase_order')
      )?{key:"item.purchaseActions",fn:function(ref){
      var item = ref.item;
return [(item.status == 1)?[(_vm.permissionCheck('modify', 'purchase_order'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]):_vm._e(),(_vm.permissionCheck('modify', 'purchaseAudit'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('audit', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.audit")))])]):_vm._e(),(_vm.permissionCheck('del', 'purchase_order'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('abolish', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.abolish")))])]):_vm._e()]:_vm._e(),(item.status == 2)?[(_vm.permissionCheck('modify', 'purchaseReceive'))?_c('v-tooltip',{attrs:{"disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toBeReceived', item)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.tobeReceived"))+" ")])]}}],null,true)}):_vm._e()]:_vm._e(),(item.status == 3)?[_c('v-tooltip',{attrs:{"disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('haveArrived', item)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.unfloatcargo"))+" ")])]}}],null,true)})]:_vm._e(),(item.status == -1)?[(_vm.permissionCheck('del', 'purchase_order'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])]):_vm._e()]:_vm._e()]}}:null,{key:"item.stockOutActions",fn:function(ref){
      var item = ref.item;
return [(item.status == 1)?[(_vm.permissionCheck('modify', 'picking'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]):_vm._e(),(_vm.permissionCheck('modify', 'pickingAudit'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('audit', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.audit")))])]):_vm._e(),(_vm.permissionCheck('del'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('abolish', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.abolish")))])]):_vm._e()]:_vm._e(),(item.status == 2)?[(_vm.permissionCheck('modify', 'pickDelivery'))?_c('v-tooltip',{attrs:{"disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deliveryCompleted', item)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.deliveryCompleted"))+" ")])]}}],null,true)}):_vm._e()]:_vm._e(),(item.status == 3 && _vm.permissionCheck('modify'))?[_c('v-tooltip',{attrs:{"disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('saleReturn', item)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.saleReturn"))+" ")])]}}],null,true)})]:_vm._e()]}},{key:"item.stockOutProductCategory",fn:function(){return [_c('span',[_c('v-chip',{staticClass:"my-0",attrs:{"dark":"","small":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.commodity"))+" ")])],1)]},proxy:true},{key:"item.stockList",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 ma-0 border-0",attrs:{"color":"white","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.goodsJump(item.id)}}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"color":"blue"}},[_vm._v("Stock List")])])]}}],null,true)})]}},{key:"item.stockInOut",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 ma-0 border-0",attrs:{"color":"white","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.LogJump(item.id)}}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"color":"blue"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.stockInOutRec")))])])]}}],null,true)})]}},{key:"item.returnStatus",fn:function(ref){
      var item = ref.item;
return _vm._l((item.return_order),function(returnOrder){return _c('span',{key:returnOrder.id,staticClass:"px-1"},[(returnOrder.status == 1)?_c('span',[_c('v-chip',{staticClass:"my-1",attrs:{"dark":"","small":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('saleReturn', item, returnOrder.id, returnOrder.status)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.application"))+" ")])],1):_vm._e(),(returnOrder.status == 2)?_c('span',[_c('v-chip',{staticClass:"my-1",attrs:{"dark":"","small":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('saleReturn', item, returnOrder.id, returnOrder.status)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.confirm"))+" ")])],1):_vm._e(),(returnOrder.status == 3)?_c('span',[_c('v-chip',{staticClass:"my-1",attrs:{"dark":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('saleReturn', item, returnOrder.id, returnOrder.status)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.offStocks"))+" ")])],1):_vm._e(),(returnOrder.status == -1)?_c('span',[_c('v-chip',{staticClass:"my-1",attrs:{"dark":"","small":"","color":"#9E9E9E"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('saleReturn', item, returnOrder.id, returnOrder.status)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.abolish"))+" ")])],1):_vm._e()])})}},{key:"item.arrivalGood",fn:function(ref){
      var item = ref.item;
return [(
          _vm.permissionCheck('modify') &&
          _vm.permissionCheck('del') &&
          item.status <= 0
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]):_vm._e(),(_vm.permissionCheck('del') && item.status <= 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])]):_vm._e(),(_vm.permissionCheck('list', 'customerDelive') && item.status === 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('viewitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.view")))])]):_vm._e(),(
          _vm.permissionCheck('list', 'customerDelive') &&
          (item.status === 0 || item.status === 1)
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"light-green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('arrived', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-truck-fast ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.arrived")))])]):_vm._e(),(_vm.permissionCheck('list', 'customerDelive') && item.status === 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"black"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('freight', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-calculator ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.freight")))])]):_vm._e()]}},{key:"item.stockOutStatus",fn:function(ref){
        var item = ref.item;
return [(item.status == 1)?_c('v-chip',{attrs:{"dark":"","color":"purple"}},[_vm._v("Pending Review")]):_vm._e(),(item.status == 2)?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v("Completed")]):_vm._e(),(item.status == 3)?_c('v-chip',{attrs:{"dark":"","color":"orange"}},[_vm._v("Out of Stock")]):_vm._e(),(item.status == -1)?_c('v-chip',{attrs:{"dark":"","color":"red"}},[_vm._v("Cancel")]):_vm._e()]}},{key:"item.CustomerListAction",fn:function(ref){
        var item = ref.item;
return [(_vm.permissionCheck('modify'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]):_vm._e(),(_vm.permissionCheck('del'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])]):_vm._e(),(_vm.permissionCheck('list', 'order_receipt'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"light-green"},on:{"click":function($event){$event.stopPropagation();return _vm.customerGather(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.Gathering")))])]):_vm._e(),(_vm.permissionCheck('list', 'customer_account'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"dark-grey"},on:{"click":function($event){$event.stopPropagation();return _vm.customerUser(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-multiple ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.customerAccount")))])]):_vm._e()]}},{key:"item.SupplierActions",fn:function(ref){
        var item = ref.item;
return [(_vm.permissionCheck('modify'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]):_vm._e(),(_vm.permissionCheck('del'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])]):_vm._e(),(_vm.permissionCheck('list', 'supplier_account'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"light-green"},on:{"click":function($event){$event.stopPropagation();return _vm.supplierUser(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-box ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.Supplier")))])]):_vm._e(),(_vm.permissionCheck('list', 'supplier_pay'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"dark-grey"},on:{"click":function($event){$event.stopPropagation();return _vm.supplierPay(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.paymentOrder")))])]):_vm._e()]}},{key:"item.shippingStatus",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.getShippingStatus(item)))])]}},{key:"item.change",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.change < 0 ? 'red' : 'blue',"dark":""}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.change))+" ")])]}},{key:"item.note",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.note))+" "),(item.note.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more")]):_vm._e()]}},{key:"item.address",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.address))+" "),(item.address.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more ")]):_vm._e()]}},{key:"item.order_num",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.order_num))+" ")]}},{key:"item.order_price",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.order_price))+" ")]}},{key:"item.number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.number))+" ")]}},{key:"item.goods_num",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.goods_num))+" ")]}},{key:"item.totalReceive",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.totalReceive))+" ")]}},{key:"item.totalReturn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.totalReturn))+" ")]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.price))+" ")]}},{key:"item.inventory",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.inventory))+" ")]}},{key:"item.total_order",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.total_order))+" ")]}},{key:"item.total_money",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.total_money))+" ")]}},{key:"item.totalQty",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.totalQty))+" ")]}},{key:"item.totalProductType",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.totalProductType))+" ")]}}],null,true)}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"text-center mt-4"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"4"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"1"}},[_c('v-text-field',{staticClass:"noUnderline",attrs:{"readonly":"","label":"Total Pages","value":_vm.pageCount,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"min":"1","label":"Jump to page","type":"number"},on:{"focus":function($event){return $event.target.select()},"change":_vm.pageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=_vm._n($$v)},expression:"page"}})],1)],1)],1):_c('div',{staticClass:"text-center mt-4"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"8"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"noUnderline",attrs:{"readonly":"","label":"Total Pages","value":_vm.pageCount,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"min":"1","label":"Jump to page","type":"number"},on:{"focus":function($event){return $event.target.select()},"change":_vm.pageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=_vm._n($$v)},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }